import React, {useEffect} from "react";
import Footer from "../global/Footer";
import { HashLink } from "react-router-hash-link";
import Contact from "../contact/Contact";
import ReactGA from 'react-ga4';

function GalleryTheICE2023(){

    useEffect(() =>{
        setGA();
    });

    const setGA = () => {
        ReactGA.send({ hitType: "pageview", page: "/theice2023", title: "Portfolio The ICE 2023" });
    };

    return(
        <>
            <section id ="portfolio">
                <div className="container d-flex flex-column">
                    <div className="row align-items-center justify-content-lg-between mt-15 mt-md-10">
                        <div className="text-center justify-content-center">
                            <h1 className="text-primary">
                                Portfolio
                            </h1>
                        </div>
                    </div>
                    <div>
                        <h3 className="text-center py-5 text-secondary">
                            THE ICE (2023)
                        </h3>
                    </div>
                    <div className="row py-5">
                        <div className="col-4 col-lg-4 mb-4 mb-lg-0 text-center">
                            <a href="home">
                                <i className="bi bi-chevron-double-left"></i>
                                    <span>Home</span> 
                            </a>
                        </div>
                        <div className="col-4 col-lg-4 mb-4 mb-lg-0 text-center">
                        </div>
                        <div className="col-4 col-lg-4 mb-4 mb-lg-0 text-center">
                            <a href="portfolio-como-2023">
                                    <span>Fuori Concorso (2023)</span> 
                                <i className="bi bi-chevron-double-right"></i>
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4 mb-2 mb-lg-0">
                            <img
                                src="./assets/images/photos/theice2023/YLT05207.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="Lancia Stratos HF Zero, THE ICE (2023) by MrYLT"
                            />
                            <img
                                src="./assets/images/photos/theice2023/YLT06617.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="Ferrari Daytona, THE ICE (2023) by MrYLT"
                            />
                            <img
                                src="./assets/images/photos/theice2023/YLT08932.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="Auto Union Type C, THE ICE (2023) by MrYLT"
                            />
                        </div>
                        <div className="col-12 col-md-4 mb-2 mb-lg-0">
                            <img
                                src="./assets/images/photos/theice2023/YLT08900.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="THE ICE (2023) by MrYLT"
                            />
                            <img
                                src="./assets/images/photos/theice2023/YLT08926.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="MOET & CHANDON, THE ICE (2023) by MrYLT"
                            />
                            <img
                                src="./assets/images/photos/theice2023/YLT06788.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="Ferrari, THE ICE (2023) by MrYLT"
                            />
                        </div>
                        <div className="col-12 col-md-4 mb-2 mb-lg-0">
                            <img
                                src="./assets/images/photos/theice2023/YLT07777.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="Lancia Stratos HF Zero, THE ICE (2023) by MrYLT"
                            />
                            <img
                                src="./assets/images/photos/theice2023/YLT08880.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="Ferrari 275 GTB, THE ICE (2023) by MrYLT"
                            />
                            <img
                                src="./assets/images/photos/theice2023/YLT05231.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="Lamborghini Countach & Lamborghini Miura, by MrYLT"
                            />
                        </div>
                    </div>
                    <div className="row py-5">
                        <div className="col-4 col-lg-4 mb-4 mb-lg-0 text-center">
                            <a href="home">
                                <i className="bi bi-chevron-double-left"></i>
                                    <span>Home</span> 
                            </a>
                        </div>
                        <div className="col-4 col-lg-4 mb-4 mb-lg-0 text-center">
                            <HashLink smooth to="#portfolio" className=" text-black"><i className="bi bi-chevron-double-up"></i> TOP <i className="bi bi-chevron-double-up"></i></HashLink>
                        </div>
                        <div className="col-4 col-lg-4 mb-4 mb-lg-0 text-center">
                            <a href="portfolio-como-2023">
                                    <span>FuoriConcorso (2023)</span> 
                                <i className="bi bi-chevron-double-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <Contact />
            
            <Footer/>
        </>
    );
}

export default GalleryTheICE2023