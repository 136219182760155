import React from "react";
import { NavHashLink } from "react-router-hash-link";


function HeaderNFT(){

    return(
        <>
            {/* navbar */}
            <nav className="navbar navbar-expand bg-red navbar-sticky navbar-light" id="navbar" data-bs-parent="#mainNav">
                <div className="container">

                    <a href="/" className="navbar-brand">
                        <p className="text-primary-white fs-2">MrYLT</p>
                    </a>

                    <div className="collapse navbar-collapse" id="navbar" data-bs-parent="#mainNav">
                        <ul className="navbar-nav">
                            <li className="nav-item mx-2">
                                <NavHashLink to="/#portfolio">
                                    <div className="text-primary">
                                        <span className="text-white fs-6">Portefolio</span>
                                    </div>
                                </NavHashLink>
                            </li>
                            <li className="nav-item mx-2">
                                <NavHashLink to="/#print">
                                    <div className="text-primary fs-6">
                                        <span className="text-white">Print</span>
                                    </div>
                                </NavHashLink>
                            </li>
                            <li className="nav-item mx-2">
                                <NavHashLink to="/#contact">
                                    <div className="text-primary fs-6">
                                        <span className="text-white">Contact</span>
                                    </div>
                                </NavHashLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>

    );
}

export default HeaderNFT