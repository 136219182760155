import React, {useEffect} from "react";
import { HashLink } from "react-router-hash-link";
import Footer from "../global/Footer";
import Contact from "../contact/Contact";
import ReactGA from 'react-ga4';

function GalleryComo2023(){

    useEffect(() =>{
        setGA();
    });

    const setGA = () => {
        ReactGA.send({ hitType: "pageview", page: "/como2023", title: "Portfolio Como 2023" });
    };

    return(
        <>
            <section id ="portfolio">
                <div className="container d-flex flex-column">
                    <div className="row align-items-center justify-content-lg-between mt-15 mt-md-10">
                        <div className="text-center justify-content-center">
                            <h1 className="text-primary">
                                Portfolio
                            </h1>
                        </div>
                    </div>
                    <div>
                        <h3 className="text-center py-5 text-secondary">
                        FUORI CONCORSO (2023)
                        </h3>
                    </div>
                        <div className="row py-5">
                            <div className="col-4 col-lg-4 mb-4 mb-lg-0 text-center">
                                <a href="home">
                                    <i className="bi bi-chevron-double-left"></i>
                                        <span>Home</span> 
                                </a>
                            </div>
                            <div className="col-4 col-lg-4 mb-4 mb-lg-0 text-center">
                            </div>
                            <div className="col-4 col-lg-4 mb-4 mb-lg-0 text-center">
                                <a href="portfolio-theice-2023">
                                        <span> THE ICE (2023)</span> 
                                    <i className="bi bi-chevron-double-right"></i>
                                </a>
                            </div>
                        </div>
                    <div className="row">
                        <div className="col-12 col-md-4 mb-2 mb-lg-0">
                            <img
                                src="./assets/images/photos/como2023/YLT01343.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="FUORI CONCORSO (2023)"
                            />
                            <img
                                src="./assets/images/photos/como2023/YLT01394.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="FUORI CONCORSO (2023) - Porsche 935 Martini Racing"
                            />
                            <img
                                src="./assets/images/photos/como2023/YLT01980.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="FUORI CONCORSO (2023) - Racing Legend"
                            />
                        </div>
                        <div className="col-12 col-md-4 mb-2 mb-lg-0">
                            <img
                                src="./assets/images/photos/como2023/YLT01204.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="FUORI CONCORSO (2023) - Porsche 911 992 GT3 RS"
                            />
                            <img
                                src="./assets/images/photos/como2023/YLT01914.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="FUORI CONCORSO (2023) - Fiat Abarth"
                            />
                            <img
                                src="./assets/images/photos/como2023/YLT01934.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="FUORI CONCORSO (2023) - Maserati MC12 Versione Corsa"
                            />
                        </div>
                        <div className="col-12 col-md-4 mb-2 mb-lg-0">
                            <img
                                src="./assets/images/photos/como2023/YLT01300.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="FUORI CONCORSO (2023) - Porsche Speedster Lineup"
                            />
                            <img
                                src="./assets/images/photos/como2023/YLT02010.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="FUORI CONCORSO (2023) - Ferrari FXX K Evo"
                            />
                            <img
                                src="./assets/images/photos/como2023/YLT01968.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="FUORI CONCORSO (2023) - Racing Legend"
                            />
                        </div>
                        <div className="row py-5">
                            <div className="col-4 col-lg-4 mb-4 mb-lg-0 text-center">
                                <a href="home">
                                    <i className="bi bi-chevron-double-left"></i>
                                        <span>Home</span> 
                                </a>
                            </div>
                            <div className="col-4 col-lg-4 mb-4 mb-lg-0 text-center">
                                <HashLink smooth to="#portfolio" className=" text-black"><i className="bi bi-chevron-double-up"></i> TOP <i className="bi bi-chevron-double-up"></i></HashLink>
                            </div>
                            <div className="col-4 col-lg-4 mb-4 mb-lg-0 text-center">
                                <a href="portfolio-theice-2023">
                                        <span> THE ICE (2023)</span> 
                                    <i className="bi bi-chevron-double-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Contact />
            <Footer/>
        </>
    );    
}

export default GalleryComo2023