import React from "react";

function Footer(){

    return(
        <>
            <footer className="py-15 py-xl-20 bg-red inverted">
                <div className="container">
                    <div className="row g-2 g-lg-6 mb-8">
                        <div className="col-lg-6">
                            <h5>
                                MrYLT <br />
                                Freelance Automotive Photographer.
                            </h5>
                        </div>
                        <div className="col-lg-6 text-lg-end">
                            <span className="h5">Switzerland</span>
                        </div>
                    </div>
                    <div className="row mb-8">
                        <div className="col">
                            <hr />
                        </div>
                    </div>
                    <div className="row g-0 g-lg-6 text-secondary">
                        <div className="col-lg-6 text-lg-end order-lg-2">
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                <a href="https://www.instagram.com/mr.ylt/" className="text-reset">
                                    instagram
                                </a>
                                </li>
                                <li className="list-inline-item ms-1">
                                <a href="https://twitter.com/YannMrYLT" className="text-reset">
                                    twitter
                                </a>
                                </li>
                                <li className="list-inline-item ms-1">
                                <a href="https://www.facebook.com/mryltofficial" className="text-reset">
                                    facebook
                                </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 order-lg-1">
                            <p>Copyrights © 2023</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;