import NotFound from './component/global/NotFound'
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';
import React, {useEffect } from 'react';
import './App.css';
import ReactGA from 'react-ga4'; 

import Home from './component/Home';
import GalleryTheICE2023 from './component/gallery/GalleryTheICE2023';
import GalleryComo2023 from './component/gallery/GalleryComo2023';
import NFT from './component/nft/NFT';

function App() {

  const TRACKING_ID = "G-NDD5BQ2ZR8";

  useEffect(() => {
    setGA();
  });

  const setGA = () => {
    ReactGA.initialize(TRACKING_ID);
  };

  return (
    <Router>
      <Routes>
        <Route index element={<Home />}></Route>
        <Route path='home' element={<Home />}></Route>
        <Route path='portfolio-theice-2023' element={<GalleryTheICE2023 />}></Route>
        <Route path='portfolio-como-2023' element={<GalleryComo2023 />}></Route>
        <Route path='nft' element={<NFT />}></Route>
        <Route path='*' element={<NotFound />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
