import React from "react";

function NFTCarDetails (){
    return(
        <>
        <div className="row justify-content-lg-between">
            <h5 className="text-secondary">
                <a href="https://foundation.app/collection/a-car-details-story" className="text-black" target="_blank" rel="noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-car-front-fill text-red" viewBox="0 0 16 16">
                        <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z"/>
                    </svg>
                    <span className="mx-2">
                        A Car Details Story
                    </span>
                </a>
            </h5>
        </div>
        <div className="row">
            <div className="col-12 col-md-4 mb-2 mb-lg-0">
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/a-car-details-story/10" target="_blank" rel="noreferrer">
                        <img
                            src="./assets/images/nft/turbor.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="RUF Turbo R Guard Red by MrYLT"
                        />Turbo R
                    </a>
                    <br/> 0.069Ξ
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/a-car-details-story/3" target="_blank" rel="noreferrer">
                        <img
                            src="./assets/images/nft/carbonblue.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="Bugatti Veyron 16.4 Super Sport Carbon Blue by MrYLT"
                        />
                        Carbon Blue Super Sport
                    </a>
                    <br/> 0.08Ξ
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/a-car-details-story/6" target="_blank" rel="noreferrer">
                        <img
                            src="./assets/images/nft/SunfireYellow.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="Corvette Stingray C2 Sunfire Yellow by MrYLT"
                        />Sunfire Yellow
                    </a>
                    <br/> SOLD!
                </div>
            </div>
            <div className="col-12 col-md-4 mb-2 mb-lg-0">
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/a-car-details-story/4" target="_blank" rel="noreferrer">
                        <img
                            src="./assets/images/nft/911.png"
                            className="w-100 shadow-1-strong rounded"
                            alt="911 Targa Carrera 4 GTS by MrYLT"
                        />
                        Right on Time
                    </a>
                    <br/> 0.0911Ξ
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/a-car-details-story/9" target="_blank" rel="noreferrer">
                        <img
                            src="./assets/images/nft/gto.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="Ferrari 250 GTO by MrYLT"
                        />
                        250 GTO
                    </a>
                    <br/> 0.1Ξ
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/a-car-details-story/5" target="_blank" rel="noreferrer">
                        <img
                            src="./assets/images/nft/PureBlue.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="Porsche 911 997.2 Speedster Pure Blue by MrYLT"
                        />
                        Pure Blue
                    </a>
                    <br/> SOLD!
                </div>
            </div>
            <div className="col-12 col-md-4 mb-2 mb-lg-0">
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/a-car-details-story/8" target="_blank" rel="noreferrer">
                        <img
                        src="./assets/images/nft/grigio.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="Ferrari FXX K Evo Grigio Alloy by MrYLT"
                        />
                        Grigio Alloy
                    </a>
                    <br/> 0.08Ξ
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/a-car-details-story/1" target="_blank" rel="noreferrer">
                        <img
                        src="./assets/images/nft/sc.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="Ferrari FXX K Evo Grigio Alloy by MrYLT"
                        />
                        Super Carrera
                    </a>
                    <br/> 0.08Ξ
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/a-car-details-story/2" target="_blank" rel="noreferrer">
                        <img
                            src="./assets/images/nft/coat.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="Porsche 911 2.2 S Targa by MrYLT"
                        />
                        Coat of Arms
                    </a>
                    <br/> SOLD!
                </div>
            </div>
        </div>
        </>
    )
}

export default NFTCarDetails;