import React, {useEffect} from "react";
import { HashLink } from "react-router-hash-link";
import HeaderNFT from "./HeaderNFT";
import NFTCarDetails from "./NFTCarDetails";
import NFTPetrolheadStory from "./NFTPetrolheadStory";
import Footer from "../global/Footer";
import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet";

function NFT(){

    useEffect(() =>{
        setGA();
    });

    const setGA = () => {
        ReactGA.send({ hitType: "pageview", page: "/nft", title: "NFT" });
    };

    return(
        <>
            <HeaderNFT/>
            <Helmet>
                <title>NFT</title>
                <meta name="mrylt nft" content="mrylt car photography nft" />
            </Helmet>
            <section id ="nft" className="py-15">
                <div className="container d-flex flex-column">
                    <div className="row align-items-center justify-content-lg-between mt-15 mt-md-10">
                        <div className="text-center justify-content-center">
                            <h1 className="text-primary">
                                NFT
                            </h1>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-lg-between">
                        <h3 className="text-center py-5 text-secondary">
                            <a className="text-black" href="https://foundation.app/@mrylt?tab=home"> 
                                Foundation
                            </a>
                        </h3>
                    </div>
                    
                    <NFTCarDetails />

                    <NFTPetrolheadStory/>

                    <div className="row py-5">
                        <div className="col-4 col-lg-4 mb-4 mb-lg-0 text-center"></div>
                        <div className="col-4 col-lg-4 mb-4 mb-lg-0 text-center">
                            <HashLink smooth to="#portfolio" className=" text-black"><i className="bi bi-chevron-double-up"></i> TOP <i className="bi bi-chevron-double-up"></i></HashLink>
                        </div>
                        <div className="col-4 col-lg-4 mb-4 mb-lg-0 text-center"></div>
                    </div>

                </div>
            </section>
            <Footer />
        </>
    );
}

export default NFT;