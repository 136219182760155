import React from "react";
import { Helmet } from "react-helmet";

function PreviewTheICE2023(){
    return(
        <>
            <Helmet>
                <title>Portfolio The ICE 2023</title>
                <meta name="portfolio The ICE 2023" content="The ICE 2023 car photography portfolio of mrylt" />
            </Helmet>
            <div>
                <h3 className="text-center py-5 text-secondary">
                    THE ICE
                </h3>
            </div>
            <div className="row">
                <div className="col-12 col-md-4 mb-2 mb-lg-0">
                    <img
                        src="./assets/images/photos/theice2023/YLT05207.jpg"
                        className="w-100 shadow-1-strong rounded mb-4"
                        alt="Boat on Calm Water"
                    />
                    <img
                        src="./assets/images/photos/theice2023/YLT06617.jpg"
                        className="w-100 shadow-1-strong rounded mb-4"
                        alt="Wintry Mountain Landscape"
                    />
                </div>
                <div className="col-12 col-md-4 mb-2 mb-lg-0">
                    <img
                        src="./assets/images/photos/theice2023/YLT08900.jpg"
                        className="w-100 shadow-1-strong rounded mb-4"
                        alt="Mountains in the Clouds"
                    />
                    <img
                        src="./assets/images/photos/theice2023/YLT08926.jpg"
                        className="w-100 shadow-1-strong rounded mb-4"
                        alt="Boat on Calm Water"
                    />
                </div>
                <div className="col-12 col-md-4 mb-2 mb-lg-0">
                    <img
                        src="./assets/images/photos/theice2023/YLT07777.jpg"
                        className="w-100 shadow-1-strong rounded mb-4"
                        alt="Waves at Sea"
                    />
                    <img
                        src="./assets/images/photos/theice2023/YLT06788.jpg"
                        className="w-100 shadow-1-strong rounded mb-4"
                        alt="Yosemite National Park"
                    />
                </div>
                <div className="col-12 text-center mb-10">
                    <a href="portfolio-theice-2023">
                        <i className="bi bi-chevron-double-right"></i>
                        <span className="mx-2">See more</span> 
                        <i className="bi bi-chevron-double-right"></i>
                    </a>
                </div>
            </div>
        </>
    );
}

export default PreviewTheICE2023