import React, {useState} from "react";

function Contact(){
    const [msg, setMsg] = useState('');
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [sender, setSender] = useState('');
    const [sent, setSent] = useState(false);

    const handleMsgChange = event => {
        setMsg(event.target.value);
    }

    const handleNameChange = event => {
        setName(event.target.value);
    }

    const handleCompanyChange = event => {
        setCompany(event.target.value);
    }

    const handleSender = event => {
        setSender(event.target.value);
    }

    const handleSubmit = () => {
        const templateId = 'template_s0zwcil';
        sendFeedback(templateId)
    }

    const sendFeedback = (templateId) => {
        window.emailjs.send(
          'service_wictclb',
          templateId,
          {message: msg, from_name: name+' - '+ company, reply_to: sender}
        ).then(res => {
            document.getElementById('form').remove();
            setSent(true);
            console.log('Email successfully sent!')
        })
        // Handle errors here however you like, or use a React error boundary
        .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
    };


    return(
        <>
            <section className="py-10 py-xl-15 border-top" id="contact">
                <div className="container">
                    <div className="row justify-content-center" id="form">
                        <div className="col-lg-8 mb-5">
                            <h2>
                                Let's make something big.
                                <span className="d-block"><span className="text-primary">Together.</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-car-front mx-2" viewBox="0 0 16 16">
                                    <path d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17 1.247 0 2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276Z"/>
                                    <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.807.807 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155 1.806 0 4.037-.084 5.592-.155A1.479 1.479 0 0 0 15 9.611v-.413c0-.099-.01-.197-.03-.294l-.335-1.68a.807.807 0 0 0-.43-.563 1.807 1.807 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3H4.82Z"/>
                                    </svg></span>
                                <span className="d-block">
                                </span>
                            </h2>
                        </div>
                        <div className="col-lg-8">
                            <form action="#" className="row g-3">
                                <div className="col-md-6">
                                    <label htmlFor="yourName" className="form-label">
                                    Your Name
                                    </label>
                                    <input
                                    type="text"
                                    className="form-control"
                                    id="yourName"
                                    placeholder="Your Name"
                                    onChange={handleNameChange}
                                    value={name}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="yourCompany" className="form-label">
                                    Your Company
                                    </label>
                                    <input
                                    type="text"
                                    className="form-control"
                                    id="yourCompany"
                                    placeholder="Your Company"
                                    onChange={handleCompanyChange}
                                    value={company}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="yourEmail" className="form-label">
                                    Your Email
                                    </label>
                                    <input
                                    type="text"
                                    className="form-control"
                                    id="yourEmail"
                                    placeholder="your@email.com"
                                    onChange={handleSender}
                                    value={sender}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="yourMessage" className="form-label">
                                        Message
                                    </label>
                                    <textarea
                                    className="form-control"
                                    id="yourMessage"
                                    rows={3}
                                    placeholder="Message"
                                    onChange={handleMsgChange}
                                    value={msg}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <span>
                                        <a onClick={() => handleSubmit()} className="btn btn-block border rounded-pill">
                                            Send it !
                                        </a>    
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                    { 
                        sent ? 
                        <div className="row justify-content-center" id="successfulMsg">
                            <div className="col-lg-8 mb-5">
                                <h2>
                                    Thank you.
                                    <span className="d-block text-purple">I will reach back shortly.</span>
                                </h2>
                            </div>
                        </div> :
                        <></>
                    }                                        
                </div>
            </section>
        </>
    );
}

export default Contact