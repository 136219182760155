import React from "react";


function Print(){

    return(
        <>
            <section id ="print" className="border-top mb-15">
                <div className="container d-flex flex-column">
                    <div className="row align-items-center justify-content-lg-between mt-15">
                        <div className="text-center justify-content-center">
                            <h1 className="text-primary">
                                Print
                            </h1>
                            <img
                                src="./assets/images/print/250GTO.png"
                                className="w-40 shadow-1-strong rounded mb-4"
                                alt="Ferrari 250 GTO Print"
                            />
                        </div>
                    </div>
                    <h3 className="text-center text-secondary">
                        COMING SOON...                
                    </h3>                 
                </div>
            </section>
        </>
    );
}

export default Print