import React, { useEffect } from "react";
import Contact from "./contact/Contact";
import {HashLink} from 'react-router-hash-link';
import Footer from "./global/Footer";
import Print from "./Print";
import Portfolio2023 from "./Portfolio2023";
import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet";

function Home(){

    useEffect(() => {
        setGA();
    });

    const setGA = () => {
        ReactGA.send({ hitType: "pageview", page: "/home", title: "Home" });
    };

    return (
        <>
            <Helmet>
                <title>Home</title>
                <meta name="description" content="mrylt car photography" />
            </Helmet>
            <section className="bg-white">
                <div className="container d-flex flex-column py-20">
                    <div className="row align-items-center justify-content-lg-between mt-lg-10">
                        <div className="text-center">
                            <div className="row text-center justify-content-center">
                                <h1 className="text-primary text-red">
                                    MrYLT
                                </h1>
                                <h2 className="lead text-secondary mb-4">
                                    Freelance Automotive Photographer
                                </h2>
                            </div>
                            <div className="row text-center justify-content-center pt-5">
                                <div className="col-sm-2 col-3">
                                    <p className="fs-9 fs-md-6 fs-lg-4">
                                        <HashLink smooth to='#portfolio'>Portfolio</HashLink>
                                    </p>
                                </div>
                                <div className="col-sm-2 col-3">
                                    <p className="fs-9 fs-md-6 fs-lg-4">
                                        <HashLink smooth to='#print'>Print</HashLink>
                                    </p>
                                </div>
                                <div className="col-sm-2 col-3">
                                    <p className="fs-9 fs-md-6 fs-lg-4">
                                        <HashLink smooth to='#contact'>Contact</HashLink>
                                    </p>
                                </div>
                            </div>
                            <br/>
                            <h3 className="pt-10">
                                <HashLink smooth to='#portfolio'><i className="bi bi-chevron-double-down" /> </HashLink>
                            </h3>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </section>

            <Portfolio2023/>

            <Print />

            <Contact />

            <Footer />
        </>
    );
}

export default Home