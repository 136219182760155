import React from "react";
import { Helmet } from "react-helmet";

function PreviewComo2023(){

    return(
        <>
            <Helmet>
                <title>Portfolio Como 2023</title>
                <meta name="portfolio como 2023" content="como 2023 car photography portfolio of mrylt" />
            </Helmet>
            <section id ="portfolio">
                <div className="container d-flex flex-column">
                    <div>
                        <h3 className="text-center py-5 text-secondary">
                            FUORI CONCORSO (2023)
                        </h3>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4 mb-2 mb-lg-0">
                            <img
                                src="./assets/images/photos/como2023/YLT01343.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="FUORI CONCORSO (2023)"
                            />
                            <img
                                src="./assets/images/photos/como2023/YLT01394.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="FUORI CONCORSO (2023) - Porsche 935 Martini Racing"
                            />
                        </div>
                        <div className="col-12 col-md-4 mb-2 mb-lg-0">
                            <img
                                src="./assets/images/photos/como2023/YLT01204.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="FUORI CONCORSO (2023) - Porsche 911 992 GT3 RS"
                            />
                            <img
                                src="./assets/images/photos/como2023/YLT01914.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="FUORI CONCORSO (2023) - Fiat Abarth"
                            />
                        </div>
                        <div className="col-12 col-md-4 mb-2 mb-lg-0">
                            <img
                                src="./assets/images/photos/como2023/YLT01300.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="FUORI CONCORSO (2023) - Porsche Speedster Lineup"
                            />
                            <img
                                src="./assets/images/photos/como2023/YLT02010.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="FUORI CONCORSO (2023) - Ferrari FXX K Evo"
                            />
                        </div>
                        <div className="col-12 text-center mb-10">
                            <a href="portfolio-como-2023">
                                <i className="bi bi-chevron-double-right"></i>
                                    <span className="mx-2">See more</span> 
                                <i className="bi bi-chevron-double-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PreviewComo2023