import React from "react";

function NFTPetrolheadStory (){
    return(
        <>
        <div className="row justify-content-lg-between pt-10">
            <h5 className="text-secondary">
                <a href="https://foundation.app/collection/petrolhead-story" className="text-black" target="_blank" rel="noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-car-front-fill text-red" viewBox="0 0 16 16">
                        <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z"/>
                    </svg>
                    <span className="mx-2">
                        A Petrolhead Story
                    </span>
                </a>
            </h5>
        </div>
        <div className="row">
            <div className="col-12 col-md-4 mb-2 mb-lg-0">
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/petrolhead-story/2" target='_blank' rel="noreferrer">
                        <img
                            src="./assets/images/nft/targagts.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="A Targa GTS Last Kiss by MrYLT"
                        />A Targa GTS Last Kiss.
                    </a>
                    <br/> 0.1Ξ
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/petrolhead-story/25" target='_blank' rel="noreferrer">
                        <img
                            src="./assets/images/nft/fxxkevo.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="Bugatti Veyron 16.4 Super Sport Carbon Blue by MrYLT"
                        />
                        FXX K Evo
                    </a>
                    <br/> 0.1Ξ
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/petrolhead-story/4" target='_blank' rel="noreferrer">
                        <img
                            src="./assets/images/nft/afterworkdrive.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="Afterwork Drive by MrYLT"
                        />Afterwork Drive 
                    </a>
                    <br/> 0.069Ξ
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/petrolhead-story/23" target='_blank' rel="noreferrer">
                        <img
                            src="./assets/images/nft/autounion.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="Afterwork Drive by MrYLT"
                        />Auto Union Type C 
                    </a>
                    <br/> SOLD!
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/petrolhead-story/15" target='_blank' rel="noreferrer">
                        <img
                            src="./assets/images/nft/freedom.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="Afterwork Drive by MrYLT"
                        />Freedom
                    </a>
                    <br/> SOLD!
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/petrolhead-story/8" target='_blank' rel="noreferrer">
                        <img
                            src="./assets/images/nft/911targa.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="911 2.4 S Targa by MrYLT"
                        />911 2.4 S Targa
                    </a>
                    <br/> SOLD!
                </div>
            </div>
            <div className="col-12 col-md-4 mb-2 mb-lg-0">
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/petrolhead-story/26" target='_blank' rel="noreferrer">
                        <img
                            src="./assets/images/nft/356.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="Porsche 356 A Coupe by MrYLT"
                        />
                        356 A Coupe
                    </a>
                    <br/> 0.1Ξ
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/petrolhead-story/21" target='_blank' rel="noreferrer">
                        <img
                            src="./assets/images/nft/montenegrosunset.JPG"
                            className="w-100 shadow-1-strong rounded"
                            alt="Montenegro Sunset by MrYLT"
                        />
                        Montenegro Sunset
                    </a>
                    <br/> 0.08Ξ
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/petrolhead-story/19" target='_blank' rel="noreferrer">
                        <img
                            src="./assets/images/nft/sunsetmaker.png"
                            className="w-100 shadow-1-strong rounded"
                            alt="The Sunset Maker by MrYLT"
                        />
                        The Sunset Maker
                    </a>
                    <br/> 0.08Ξ
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/petrolhead-story/20" target='_blank' rel="noreferrer">
                        <img
                            src="./assets/images/nft/ontherun.png"
                            className="w-100 shadow-1-strong rounded"
                            alt="On the run by MrYLT"
                        />
                        On the run
                    </a>
                    <br/> SOLD!
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/petrolhead-story/13" target='_blank' rel="noreferrer">
                        <img
                            src="./assets/images/nft/topazblue.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="Topaz Blue by MrYLT"
                        />
                        Topaz Blue
                    </a>
                    <br/> SOLD!
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/petrolhead-story/6" target='_blank' rel="noreferrer">
                        <img
                            src="./assets/images/nft/timelessfall.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="Timeless Fall by MrYLT"
                        />
                        Timeless Fall
                    </a>
                    <br/> SOLD!
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/petrolhead-story/17" target='_blank' rel="noreferrer">
                        <img
                            src="./assets/images/nft/signalyellowgt3.png"
                            className="w-100 shadow-1-strong rounded"
                            alt="Signal Yellow GT3 by MrYLT"
                        />Signal Yellow GT3
                    </a>
                    <br/> SOLD!
                </div>
            </div>
            <div className="col-12 col-md-4 mb-2 mb-lg-0">
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/petrolhead-story/24" target='_blank' rel="noreferrer">
                        <img
                            src="./assets/images/nft/UFO.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="Lancia Stratos HF Zero by MrYLT"
                        />
                        Lancia Stratos HF Zero
                    </a>
                    <br/> 0.1Ξ
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/petrolhead-story/14" target='_blank' rel="noreferrer">
                        <img
                        src="./assets/images/nft/944.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="Winter 944 by MrYLT"
                        />
                        Winter 944
                    </a>
                    <br/> 0.08Ξ
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/petrolhead-story/1" target='_blank' rel="noreferrer">
                        <img
                            src="./assets/images/nft/wintersunset.jpeg"
                            className="w-100 shadow-1-strong rounded"
                            alt="Winter sunset over Geneva's Lake by MrYLT"
                        />
                        Winter sunset over Geneva's Lake.
                    </a>
                    <br/> SOLD!
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/petrolhead-story/18" target='_blank' rel="noreferrer">
                        <img
                            src="./assets/images/nft/80sicons.png"
                            className="w-100 shadow-1-strong rounded"
                            alt="80s Icons by MrYLT"
                        />
                        80s Icons
                    </a>
                    <br/> SOLD!
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/petrolhead-story/9" target='_blank' rel="noreferrer">
                        <img
                            src="./assets/images/nft/meanttobedriven.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="Meant to be driven by MrYLT"
                        />
                        Meant to be driven
                    </a>
                    <br/> SOLD!
                </div>
                <div className="mb-4">
                    <a href="https://foundation.app/@mrylt/petrolhead-story/5" target='_blank' rel="noreferrer">
                        <img
                            src="./assets/images/nft/amethyst.jpg"
                            className="w-100 shadow-1-strong rounded"
                            alt="Amethyst 964 Turbo by MrYLT"
                        />
                        Amethyst 964 Turbo
                    </a>
                    <br/> SOLD!
                </div>
            </div>
        </div>
        </>
    )
}

export default NFTPetrolheadStory;