import React from "react";
import PreviewTheICE2023 from "./gallery/preview/PreviewTheICE2023";
import PreviewComo2023 from "./gallery/preview/PreviewComo2023";

function Portfolio2023(){
    return(
        <>
            <section id ="portfolio">
                <div className="container d-flex flex-column">
                    <div className="row align-items-center justify-content-lg-between mt-lg-10">
                        <div className="text-center justify-content-center">
                            <h1 className="text-primary">
                                Portfolio
                            </h1>
                            <h3 className="text-primary">
                                2023
                            </h3>
                        </div>
                    </div>
                    <PreviewComo2023 />
                    <PreviewTheICE2023 />                   
                </div>
            </section>
        </>
    );
}

export default Portfolio2023